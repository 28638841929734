/* eslint-disable no-await-in-loop */
import axios from 'axios';
import { Placeholder, ERROR_STATUS, ACK_STATUS } from '../constants/constants';
import * as Endpoints from '../constants/routes';

const twoMinutes = 2 * 60 * 1000;

/**
 * @name getAcknowledgementUpdates
 * @param {String} transactionId
 * @param {Date | undefined} requestTimestamp
 * @returns {String | Object}
 */
export const getAcknowledgementUpdates = async (transactionId = '') => {
  if (!transactionId) return {};
  const endpoint = Endpoints.QR_ACK_ENDPOINT.replace(Placeholder, transactionId);
  return fetchAcknowledgementResponseData(endpoint, twoMinutes);
};

export const fetchAcknowledgementResponseData = async (endpoint, timeout = twoMinutes) => {
  if (timeout > 0) {
    const requestStartTime = Date.now();
    try {
      const response = await axios.get(endpoint, { timeout: twoMinutes });

      // If pending and not 2 minutes then fetch again
      if (response?.data?.status === ACK_STATUS.PENDING) {
        const requestEndTime = Date.now();
        const timeTakenToTimeout = requestEndTime - requestStartTime;
        return fetchAcknowledgementResponseData(endpoint, timeout - timeTakenToTimeout);
      }

      return response.data;
    } catch (error) {
      if (error.response?.status === ERROR_STATUS.Request_Timeout) {
        const requestEndTime = Date.now();
        const timeTakenToTimeout = requestEndTime - requestStartTime;
        return fetchAcknowledgementResponseData(endpoint, timeout - timeTakenToTimeout);
      }
    }
  }

  throw new Error('Acknowledgment request timedout');
};

/**
 * @name triggerHelpNeededNotification
 * @param {String} qrId
 * @param @optional {Object} latlng
 * @returns {Object} {redirectionUri, deviceId, transactionId}
 */
export const triggerHelpNeededNotification = async (qrId) => {
  if (!qrId) throw new Error('Invalid qrId');
  const endpoint = Endpoints.TRIGGER_NOTIFICATION.replace(Placeholder, qrId);
  const response = await axios.post(endpoint, {});
  return response.data;
};

/**
 * @name submitFeedback
 * @param {String} qrId
 * @param {String} transactionId
 * @param {Number} rating
 * @param {Array} cannedResponses
 * @param {String} feedackText
 */
export const submitFeedback = (qrId, transactionId, rating, eventTimestamp, cannedResponses = [], feedbackText = '') => {
  const endpoint = Endpoints.SUBMIT_FEEDBACK;
  const payload = {
    deviceId: qrId,
    rating,
    feedback: feedbackText,
    transactionId,
    cannedResponses,
    eventTimestamp,
  };
  axios.post(endpoint, payload);
};
