import { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import flatten from 'flat';

import { LOCALES, messages } from './i18n';
import * as ROUTES from './constants/routes';
import {
  TruckArrival,
  HelpRequest,
  GenericErrorPage,
  HelpOnTheWay,
  ThanksPage,
  ClubClosedPage,
} from './screens';
import { injectLanguageAndLogo } from './components';
import { getValueFromBrowserStorage, setValueInBrowserStorage } from './utils';
import { LANGUAGE_KEY } from './constants/contents';
import { COUNTRY_IDENTIFIER } from './constants/constants';

const App = () => {
  const lang = new URLSearchParams(window.location.search).get('language') === 'fr' ? LOCALES.FRENCH : LOCALES.ENGLISH;
  const languageFromBrowserStorage = getValueFromBrowserStorage(LANGUAGE_KEY, localStorage);
  const [currentLocale, changeLocale] = useState(languageFromBrowserStorage || lang);
  const isCA = useMemo(() => window.location.href?.indexOf(COUNTRY_IDENTIFIER) > -1, [window.location.href]);

  useEffect(() => {
    currentLocale && setValueInBrowserStorage(LANGUAGE_KEY, currentLocale.trim?.(), localStorage, true);
  }, [currentLocale]);

  useEffect(() => {
    const link = document.querySelector('link[rel~=\'icon\']');

    if (link) {
      if (isCA) {
        link.href = '/favicon_ca.ico';
      } else {
        link.href = '/favicon.ico';
      }
    }
  }, [isCA]);

  return (
    <IntlProvider
      messages={flatten(messages[currentLocale])}
      locale={currentLocale}
      defaultLocale={navigator.language || navigator.userLanguage}
    >
      <Router>
        <Switch>
          <Route
            exact
            path={ROUTES.HELP_NEEDED_REQUEST}
            render={() => <HelpRequest isCA={isCA} />}
          />
          <Route
            exact
            path={ROUTES.HOME}
            render={injectLanguageAndLogo(HelpOnTheWay, currentLocale, changeLocale, isCA, true)}
          />
          <Route
            exact
            path={ROUTES.TRUCK_ARRIVAL}
            render={() => <TruckArrival />}
          />
          <Route
            exact
            path={ROUTES.ERROR_PAGE}
            render={injectLanguageAndLogo(GenericErrorPage, currentLocale, changeLocale, isCA, false)}
          />
          <Route
            exact
            path={ROUTES.THANKS}
            render={() => <ThanksPage isCA={isCA} />}
          />
          <Route
            exact
            path={ROUTES.CLUB_CLOSED}
            render={injectLanguageAndLogo(ClubClosedPage, currentLocale, changeLocale, isCA, false)}
          />
        </Switch>
      </Router>
    </IntlProvider>
  );
};

export default App;
