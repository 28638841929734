import {
  SamsBlueLogo, SamsWhiteLogo, WalmartBlueLogo, WalmartWhiteLogo,
} from '../../assets/img';
import LanguageSettings from '../Language';
import './styles.css';

export function withBlueSteelTheme() {
  return (Component) => (props) => (
    <div className="column container root">
      <div className="container-background" />
      <Component {...props} />
    </div>
  );
}

export function injectLanguageAndLogo(Content, currentLocale, changeLocale, isCA, showWhite = true) {
  const whiteLogo = isCA ? WalmartWhiteLogo : SamsWhiteLogo;
  const blueLogo = isCA ? WalmartBlueLogo : SamsBlueLogo;
  const logo = showWhite ? whiteLogo : blueLogo;

  return () => (
    <div className="column container">
      <LanguageSettings {...{
        currentLocale, changeLocale, showWhite, isCA,
      }}
      />
      <img src={logo} alt="Logo" className="logo" />
      <Content isCA={isCA} />
    </div>
  );
}
