import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { getClubClosureIconAndText } from './utils';
import './styles.css';

const ClubClosedPage = () => {
  const intl = useIntl();
  const getLocaleStr = (id) => intl.formatMessage({ id });

  const { state } = useLocation();
  const { icon, text } = getClubClosureIconAndText(getLocaleStr, state);

  return (
    <div className="club-closed__container">
      <img src={icon} alt="club-closed" />
      <div className="roboto club-closed__text">
        {text}
      </div>
    </div>
  );
};

export default ClubClosedPage;
