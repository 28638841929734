import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  withBlueSteelTheme,
  Modal,
  Feedback,
  Toast,
  RectangleLayout,
  Portal,
} from '../../components';
import { useAssociateAckDetails, useReqTimeout, useBrowserUnloadActions } from './customHooks';
import { EscalationScan } from '../../assets/img';
import * as styles from './styles.css';
import '../../global/typography.css';
import {
  REQUEST_STATES,
  LABELS,
  FEEDBACK_OPTIONS,
  QR_LOCATIONS,
  KEY_FEEDBACK_SUBMITTED,
} from '../../constants/constants';
import {
  SmallIconMap,
  CoverImageMap,
  getTitleAndSupportiveText,
  getInitialRequestTimestamp,
} from './utils';
import {
  preparePayloadForStorage,
  setValueInBrowserStorage,
  getQueryParam,
  doesStringMatch,
  submitFeedback,
  getValueFromBrowserStorage,
} from '../../utils';

const HelpOnTheWay = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();

  const prevRequestPayloadIfAny = location.state ?? {};
  const {
    isDuplicateRequest,
    qrId,
    transactionId,
    eventTimestamp,
  } = prevRequestPayloadIfAny;
  const feedbackSubmitted = !!getValueFromBrowserStorage(KEY_FEEDBACK_SUBMITTED);

  const [active, setActive] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedbackListOptions, setFeedbackListOptions] = useState([]);
  const [feedbackReaction, setFeedbackReaction] = useState(null);
  const [requestState, setRequestState] = useState(() => (isDuplicateRequest ? prevRequestPayloadIfAny.requestState : REQUEST_STATES.Pending));
  const [isFeedbackSubmitted, setFeedbackSubmitted] = useState(feedbackSubmitted);

  const shouldShowReqPendingModal = (requestState === REQUEST_STATES.Pending || requestState === REQUEST_STATES.Escalated);
  const [showReqPendingModal, setShowReqPendingModal] = useState(() => shouldShowReqPendingModal && isDuplicateRequest);

  const getLocaleStr = (id) => intl.formatMessage({ id });
  const qrLocation = getQueryParam(location.search, LABELS.LOCATION);
  const isBackroom = doesStringMatch(qrLocation, QR_LOCATIONS.Backroom);

  const REQUEST_STATE_AFTER_ACK = isBackroom ? REQUEST_STATES.Trck_Delivery_On_The_Way : REQUEST_STATES.On_The_Way;

  const onRatingChange = (selectedRating) => {
    setRating(selectedRating);
    if (selectedRating > 0) {
      !isBackroom && setFeedbackListOptions(FEEDBACK_OPTIONS(getLocaleStr)[selectedRating]);
      setFeedbackReaction(getLocaleStr(`feedback_content.${selectedRating}`));
    }
  };

  /** Custom Hooks */
  const [associateName] = useAssociateAckDetails(transactionId, setRequestState, REQUEST_STATE_AFTER_ACK);
  useReqTimeout(requestState, setRequestState, prevRequestPayloadIfAny.timestamp);
  useBrowserUnloadActions(history, location, qrId, transactionId);
  /** ------------ */

  const { titleText, renderSupportiveText } = getTitleAndSupportiveText(getLocaleStr, requestState, associateName, qrLocation, qrId, props.isCA);

  useEffect(() => {
    const timestamp = getInitialRequestTimestamp(prevRequestPayloadIfAny.timestamp, isDuplicateRequest);
    const payload = preparePayloadForStorage(qrLocation, requestState, transactionId, timestamp, eventTimestamp, associateName);
    setValueInBrowserStorage(qrId, payload, localStorage);
  }, [requestState]);

  useEffect(() => {
    setValueInBrowserStorage(KEY_FEEDBACK_SUBMITTED, isFeedbackSubmitted);
  }, [isFeedbackSubmitted]);

  const resetFeedbackData = () => {
    setFeedbackReaction(null);
    setFeedbackListOptions([]);
    setShowFeedbackModal(false);
  };

  const onFeedbackSubmit = (starRating, cannedResponses, feedbackText) => {
    setActive(true);
    submitFeedback(qrId, transactionId, starRating, eventTimestamp, cannedResponses, feedbackText);
    resetFeedbackData();
    setFeedbackSubmitted(true);
  };

  const receivingHoursText = isBackroom ? getLocaleStr('receiving_hours') : '';

  return (
    <div className="column rect-container">
      <RectangleLayout
        smallIcon={SmallIconMap[requestState]}
        titleText={titleText}
        renderSupportiveText={renderSupportiveText}
        coverImage={CoverImageMap[requestState]}
        belowText={receivingHoursText}
      >
        {(requestState !== REQUEST_STATES.Pending && !isFeedbackSubmitted) && (
          <div className="feedback-container">
            <Feedback
              titleStyle="typography-regular font-400 mb-12"
              title={getLocaleStr('give_feedback')}
              starSize="20"
              numberOfStars={5}
              getLocaleStr={getLocaleStr}
              onClick={() => setShowFeedbackModal(true)}
            />
          </div>
        )}
      </RectangleLayout>
      {/* <button type="button" className="action-button" onClick={handleOnActionPress}>{Contents.IAMHERE_BUTTON}</button> */}
      {/* Todo: Update this later once integration is done */}
      {showReqPendingModal && (
        <Portal>
          <div className="font-500 portal-modal__container">
            <img src={EscalationScan} alt="finding_help-icon" className="portal-modal__icon" />
            <div className="roboto typography-regular font-400 portal-modal__text">
              <span>{getLocaleStr('still_finding_help')}</span>
            </div>
            <button
              type="button"
              className="font-500 portal-modal__button"
              onClick={() => setShowReqPendingModal(false)}
            >
              {getLocaleStr('buttons.ok')}
            </button>
          </div>
        </Portal>
      )}
      {showFeedbackModal && (
        <Modal
          size="stretch"
          title={getLocaleStr('give_feedback')}
          onClose={resetFeedbackData}
        >
          <div className="scroll-content-modal">
            <Feedback
              allowUserSelection
              titleStyle="typography-medium font-500 mb-16"
              starSize="46"
              title={getLocaleStr('how_was_exp')}
              getLocaleStr={getLocaleStr}
              numberOfStars={5}
              onFeedbackSubmit={onFeedbackSubmit}
              feedbackListOptions={feedbackListOptions}
              feedbackReaction={feedbackReaction}
              onRatingSelect={(selected) => onRatingChange(selected)}
            />
            {/* Todo - Add real content to feedback */}
          </div>
        </Modal>
      )}
      {active && <Toast message={getLocaleStr(`feedback_response_${rating > 3 ? 'good' : 'bad'}`)} onHide={setActive} />}
    </div>
  );
};

HelpOnTheWay.propTypes = {
  isCA: PropTypes.bool.isRequired,
};

export default withBlueSteelTheme(styles)(HelpOnTheWay);
